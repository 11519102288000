import React from 'react';
import { Link } from 'gatsby';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStarted from 'components/GetStarted';
// import { Usecases } from 'styles/usecases';
import FAQ from '../../components/Faq';
import FeatureContainer from '../../components/FeatureContainer';
import { signUp } from '../../utils/contentProvider/function';
import TrustedBy from '../../components/TrustedBy';
import Feedback from '../../components/Feedback/Feedback';
import TestimonialsSlider from '../../components/TestimonialsSlider/TestimonialsSlider';
import 'styles/usecases.scss';

const webDesignAgencyFaq = [
  {
    q: 'Do clients need to have an account on ruttl to view projects?',
    a: 'Absolutely not! Once your project is ready to share, simply click on the share button inside the project page. In the new screen, click on the ‘Link’ text. The shareability toggle should be turned on and the link will be copied to your clipboard. Now all you need to do is share the link with your clients to get the client feedback on the project.',
  },
  {
    q: 'How many collaborators can I add to a project?',
    a: (
      <>
        In the free version, you have the ability to add up to 5 collaborators
        on a project. However, when you subscribe to any of our plans, you get
        to add unlimited collaborators.
        <Link to="/pricing/">&nbsp;Click here&nbsp;</Link>
        to see all our plans.
      </>
    ),
  },
  {
    q: 'Can developers access website code after design changes are executed?',
    a: 'After you use the inspect feature to edit and make required changes to the website design, your developers can simply copy the entire altered code under the CSS tab in the Activities Panel. This can then be pasted directly into the code of live website files.',
  },
  {
    q: 'I don’t want rutl branding. Is Custom Branding/White labelling available?',
    a: 'Yes, custom branding or while labelling is available on ruttl. You can upload your own logo & icon and customise how ruttl looks like to your team members, collaborators and clients.',
  },
  {
    q: 'Is custom domain (CNAME) available on ruttl?',
    a: (
      <>
        Yes, as part of our team plan, we have CNAME available on ruttl. Simply
        upgrade to our team plan, head to your Account Settings &amp; follow the
        simple steps mentioned there. Alternatively, you can also follow this
        <a
          href="https://youtu.be/YBYAUiGzarM"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;quick video&nbsp;
        </a>
        (35-sec) to know more.
      </>
    ),
  },

  {
    q: 'How do I upgrade my ruttl account?',
    a: (
      <>
        In the top right menu on ruttl’s main dashboard, click on the ‘
        <a
          href="https://web.ruttl.com/billing"
          target="_blank"
          rel="noopener noreferrer">
          My Plan
        </a>
        ’ section. Here you can click on the Upgrade button, select the plan in
        monthly/annual mode that best suits you and your team and make the
        payment. Your account will be upgraded once we successfully receive your
        payment.
      </>
    ),
  },
  {
    q: 'Are there any integrations available in ruttl?',
    a: (
      <>
        Currently we have integrated Slack, Trello, Zapier, asana and Unsplash.
        <Link to="/integrations/">&nbsp;Click here&nbsp;</Link>
        to know more about our integrations.
      </>
    ),
  },
  {
    q: 'Can I switch between plans before they end?',
    a: 'Yes, it’s possible to switch but you can only upgrade your plan. Just select the plan, make the payment and your plan should be upgraded once we receive the payment!',
  },
  {
    q: 'I only want my clients to review, not edit the website design — is this possible?',
    a: 'Of course! You can choose to share the guest link with your clients, and then they won’t be able to make any edits on the website design. This way, you can use our edit mode internally only with the ones whom you have added via email address and given edit access.',
  },
  {
    q: 'Is there a public roadmap available?',
    a: (
      <>
        Yes, we have our public roadmap. Here’s the
        <a
          href="https://trello.com/b/D9T51EZX"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;link&nbsp;
        </a>
        to the same.
      </>
    ),
  },
  {
    q: 'Does ruttl support task management?',
    a: (
      <>
        No, ruttl does not support task management. If you’re looking for the
        same, we would recommend checking out
        <a href="https://brutask.com" target="_blank" rel="noopener noreferrer">
          &nbsp;Brutask&nbsp;
        </a>
        . It is a simple online to-do list for individuals and small teams. Add,
        prioritize and time box your tasks to help improve your productivity.
      </>
    ),
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));
const WebDesignAgency = ({ location }) => (
  <Layout location={location}>
    {({ toggleContactUs, toggleSignup }) => (
      <>
        <Meta
          title="The #1 Website Feedback Tool for Web Design Agencies! | ruttl"
          description="Struggling to collect feedback? Try Ruttl - the fastest website feedback tool to review developed websites."
          url="https://ruttl.com/usecases/web-design-agencies/"
        />
        <main className="usecases-styled-main">
          <section className="features-hero custom-padding grey-bg">
            <div className="container">
              <h1>
                Launch client websites faster by collecting better website
                feedback.
              </h1>
              <p>
                Say goodbye to lengthy email conversations, Powerpoints, and
                boring discussion meetings.
              </p>
              <div
                className="reveal-1 button-container"
                style={{ margin: '30px 0' }}>
                <a href="https://web.ruttl.com" className="button">
                  Get Started for Free
                </a>
              </div>
            </div>
            <TrustedBy />
          </section>
          <div className="section features-main" id="features">
            <div className="container">
              <h2 className="center mt-40">Features</h2>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <img
                        src="/assets/img/leave-comments-website.jpg"
                        alt="illustration of leaving comment on live website"
                        title="illustration of leaving comment on live website"
                      />
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-50px)' }}>
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '420px' }}>
                        Leave comments on live websites
                      </h2>
                      <p>
                        Say No to dozens of screenshots, confusions, and
                        inefficient feedback by leaving precise feedback
                        directions on live websites. Assign, mention, and
                        resolve comments and keep accountability in the loop.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Comment on Live Website
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <video
                        src="/assets/img/edit-live-website.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div className="feature-txt">
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '310px' }}>
                        Make edits on live websites
                      </h2>
                      <p>
                        An upcoming deadline? Instead of having to go to your
                        development team for minor changes, get them done easily
                        with Ruttl. Change even the tiniest details like font,
                        spacing, and live alignment.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Edit your Live Website
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <video
                        src="/assets/img/guest-commenting.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div className="feature-txt">
                      <h2 className="feature-head">
                        Get feedback directly from your clients
                      </h2>
                      <p>
                        No sign-ups! No log-ins! No email follow-ups! Just send
                        a shareability link with your clients and let them write
                        away. They can also tag other project stakeholders to
                        assign a task and get the feedback process moving
                        faster.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Collect Feedback For Free
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <video
                        src="/assets/img/view-comments-trello.mp4"
                        title="Integrate with Trello to manage and assign ruttl comments in Kanban style on your preferred Trello board."
                        aria-hidden
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-30px)' }}>
                      <h2 className="feature-head">
                        Seamless integration with third-party tools
                      </h2>
                      <p>
                        Never miss out on any comment when Ruttl integrates
                        easily with Asana, Trello, and Slack. Send comments to
                        Asana, assign due dates, level of priorities, add to
                        cards on Trello, and so much more.
                      </p>
                      <a className="button" href="/integrations/">
                        Check Out The Integrations
                      </a>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <img
                        src="/assets/img/show-activity.png"
                        alt="ruttl activities tab"
                        title="ruttl activities tab"
                      />
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-30px)' }}>
                      <h2 className="feature-head">
                        Get a quick glance at your project
                      </h2>
                      <p>
                        Check everything from website edits, content changes,
                        unresolved comments in the Activities panel. Ensure you
                        never lose track of important feedback changes.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Review your project
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          src="/assets/img/share-unlimited-access.png"
                          alt="illustration of comments resolved after done"
                          title="illustration of comments resolved after done"
                        />
                      </div>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-10px)' }}>
                      <h2 className="feature-head">
                        Say yes to great collaboration
                      </h2>
                      <p>
                        No more confusion, misunderstandings, missing out on
                        important changes. Invite everyone from your design team
                        to your content team to collaborate on live projects and
                        meet deadlines. Having real-time updates becomes easier
                        than ever with Ruttl.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Collaborating
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <video
                        src="/assets/img/responsive.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div className="feature-txt">
                      <h2 className="feature-head">
                        Deliver responsive websites to your clients
                      </h2>
                      <p>
                        Quickly switch between desktop and mobile view to keep
                        your client’s website updated and responsive across
                        devices.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Reviewing
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <img
                        src="/assets/img/record-version.png"
                        alt="versioning feature of ruttl"
                        title="versioning feature of ruttl"
                      />
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-13px)' }}>
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '360px' }}>
                        Keep a track of all website versions
                      </h2>
                      <p style={{ maxWidth: '380px' }}>
                        Need to go back to a previous version? Want to explore
                        the changes you have made to the website? You can easily
                        do that by recording your website versions and
                        navigating to them in no time.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Create Website Versions
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <img
                        src="/assets/img/show-activity.png"
                        alt="ruttl activities tab"
                        title="ruttl activities tab"
                      />
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-30px)' }}>
                      <h2 className="feature-head">
                        Active CNAME to get your own custom domain
                      </h2>
                      <p>
                        Want to add a personal touch to your feedback tool? You
                        can now create your own custom domain for your design
                        agency.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Check The CNAME Feature
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          src="/assets/img/leave-video-comments.png"
                          alt="illustration of video comments"
                          title="illustration of video comments"
                        />
                      </div>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(0px)' }}>
                      <h2 className="feature-head">
                        Impress your client with custom branding
                      </h2>
                      <p>
                        Replace Ruttl’s logo and icon with your design agency’s
                        logos and icons. You can even choose your preferred
                        primary color and text highlight color for the app’s
                        CTAs. Personalization becomes easier with Ruttl.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Try Custom Branding
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
            </div>
          </div>
          <TestimonialsSlider greybg />
          <Feedback />

          <FAQ
            headingCTA
            toggleContactUs={toggleContactUs}
            greyBg
            data={webDesignAgencyFaq}
          />
          <div className="get-started-center">
            <GetStarted toggleSignup={toggleSignup} />
          </div>
        </main>
      </>
    )}
  </Layout>
);

WebDesignAgency.propTypes = {
  location: PropTypes.object,
};

export default WebDesignAgency;
